import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NotFound from './NotFound';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Footer from '../components/common/Footer';

import { setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';
import StepByStepForm, {
    MultipleChoice,
} from '../components/common/StepByStepForm';
import Loading from '../components/common/Loading';
import HTMLParse from '../components/common/HTMLParse';
import { Close } from '@mui/icons-material';
import { getPriceString, pluralize } from '../scripts/language';
import { getTitles, useYup } from '../scripts/cms';
import { AccountContext } from '../components/supertokens/Account';
import NoData from '../components/common/NoData';
import { toast } from 'react-toastify';
import BusinessModal from '../components/modal/BusinessModal';
import InputForm from '../components/common/InputForm';
import occupancyDeclaration from '../constants/occupancyDeclarations';
import { DataGrid } from '@mui/x-data-grid';
import PropertyFeatures from '../components/auction/PropertyFeatures';

function PostPurchasePage() {
    const account = useContext(AccountContext);

    setTitle('Post-Purchase Procedure');

    const { slug } = useParams();
    const [data, setData] = useState();
    const [valid, setValid] = useState(true);
    const [errors, setErrors] = useState();
    const [loading, setLoading] = useState(false);
    const [brokers, setBrokers] = useState([]);

    const yup = useYup(
        [
            [
                {
                    id: 'purchaser',
                },
            ],
            [
                {
                    id: 'people',
                    inputs: getPersonInputs(false),
                },
            ],
            [
                {
                    id: 'officers',
                    inputs: getPersonInputs(true),
                },
            ],
            [
                {
                    id: 'business',
                    inputs: getBusinessInputs(),
                },
            ],
        ],
        {
            people: [],
            business: [],
            officers: [],
            upgrades: [],
        }
    );
    const { getValues, setValue, watch } = yup;

    const purchaser = watch('purchaser');

    useEffect(() => {
        // Get the auction data
        getPurchaseData();
    }, [slug]);

    useEffect(() => {
        // Whenever the purchaser is changed, autofill the correct fields with the bidder's user info
        if (+purchaser > 0) {
            const { user } = data;
            const userData = [
                {
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    email: user.email || '',
                },
            ];

            switch (+purchaser) {
                case purchaserTypes.INDIVIDUAL.id:
                    // Empty officers
                    setValue('officers', []);
                    // User is the first person
                    setValue('people', userData);
                    // Reset business
                    setValue('business', []);
                    break;
                case purchaserTypes.CORPORATE.id:
                    // Empty officers
                    setValue('officers', userData);
                    // User is the first person
                    setValue('people', []);
                    // Set occupancy declaration to safe value
                    setValue(
                        'occupancyDeclaration',
                        occupancyDeclaration.INVESTMENT.id
                    );
                    break;
                case purchaserTypes.CORPORATE_INDIVIDUAL.id:
                    // Empty officers
                    setValue('officers', userData);
                    // User is the first person
                    setValue('people', [{}]);
                    // Set occupancy declaration to safe value
                    setValue(
                        'occupancyDeclaration',
                        occupancyDeclaration.INVESTMENT.id
                    );
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaser]);

    // Loading
    if (!data && valid) {
        return <Loading />;
    }

    // Invalid
    if (!valid) {
        return <NotFound />;
    }

    // Already submitted
    if (data.purchase) {
        return (
            <Box backgroundColor="tint">
                <PaddedBox>
                    <StepByStepForm
                        yup={yup}
                        handleSubmit={() =>
                            (window.location.href = `/purchase/${slug}`)
                        }
                        pages={[
                            {
                                body: (
                                    <Summary
                                        yup={yup}
                                        auction={data.auction}
                                        upgrades={data.upgrades}
                                    />
                                ),
                                button: 'View Your Purchase Tasks',
                            },
                        ]}
                    />
                </PaddedBox>
                <Footer />
            </Box>
        );
    }

    // Expired
    if (data.auction.purchaseExpired) {
        return (
            <Box>
                <Box display="flex" margin="auto" height="calc(100vh - 70px)">
                    <NoData
                        title="Purchase Expired"
                        description="If you would like to regain access to this purchase, please contact an administrator."
                    />
                </Box>
                <Footer />
            </Box>
        );
    }

    function getPurchaseData() {
        // Main data
        axios
            .get(`/postPurchase/get/${slug}`)
            .then((res) => {
                setData(res.data);

                // Load up form with existing data
                const { purchase, upgrades } = res.data;
                if (purchase) {
                    // Upgrade options
                    const upgradeChoices = [];
                    for (let i = 0; i < upgrades.length; i++) {
                        const upgradeGroup = upgrades[i];
                        for (let j = 0; j < upgradeGroup.length; j++) {
                            const upgrade = upgradeGroup[j];
                            for (
                                let k = 0;
                                k < upgrade.upgradeOptions.length;
                                k++
                            ) {
                                const upgradeOption = upgrade.upgradeOptions[k];
                                if (
                                    upgradeOption.propertyUpgradeOption.selected
                                ) {
                                    upgradeChoices.push(
                                        upgradeOption.id.toString()
                                    );
                                }
                            }
                        }
                    }
                    setValue('upgrades', upgradeChoices);

                    // Signers
                    const people = [];
                    const officers = [];
                    for (let i = 0; i < purchase.signatures.length; i++) {
                        const signature = purchase.signatures[i];
                        if (signature.officer) {
                            officers.push(signature);
                        } else {
                            people.push(signature);
                        }
                    }
                    setValue('officers', officers);
                    setValue('people', people);
                }
            })
            .catch(function (err) {
                setValid(false);
            });

        // Get their brokers
        axios
            .get(`/broker/getBrokers`)
            .then((res) => {
                setBrokers(res.data);
            })
            .catch(function () {});
    }

    function handleSubmit() {
        setLoading(true);
        setErrors();

        axios
            .post(`/postPurchase/submit`, { ...getValues(), slug })
            .then((res) => {
                window.location.href = `/purchase/${slug}`;
            })
            .catch(function (err) {
                setErrors({
                    server:
                        err?.response?.data ||
                        'Unable to submit your post-purchase form. Please contact support.',
                });
                getPurchaseData();
            })
            .finally(function (err) {
                setLoading(false);
            });
    }

    function getPurchaserForm() {
        switch (+purchaser) {
            case purchaserTypes.INDIVIDUAL.id:
                return {
                    body: (
                        <PurchaserContainer
                            title="Add Principal Purchaser"
                            items={[
                                <PersonForm
                                    yup={yup}
                                    title="Purchaser"
                                    attribute="people"
                                    user={data.user}
                                    addMore="Add Another Purchaser"
                                    max={data.config.individualMax}
                                />,
                            ]}
                        />
                    ),
                    validate: ['people'],
                    yup,
                };
            case purchaserTypes.CORPORATE.id:
                return {
                    body: (
                        <PurchaserContainer
                            title="Add Corporate Purchaser"
                            items={[
                                <BusinessForm yup={yup} attribute="business" />,
                                <PersonForm
                                    yup={yup}
                                    title="Signing Officer"
                                    attribute="officers"
                                    user={data.user}
                                    addMore="Add Signing Officer"
                                    officer
                                    max={data.config.officerMax}
                                />,
                            ]}
                        />
                    ),
                    validate: ['business', 'officers'],
                    yup,
                };
            case purchaserTypes.CORPORATE_INDIVIDUAL.id:
                return {
                    body: (
                        <PurchaserContainer
                            title="Add Corporate Purchaser"
                            items={[
                                <BusinessForm yup={yup} attribute="business" />,
                                <PersonForm
                                    yup={yup}
                                    title="Signing Officer"
                                    attribute="officers"
                                    addMore="Add Signing Officer"
                                    officer
                                    max={data.config.officerMax}
                                />,
                                <PersonForm
                                    yup={yup}
                                    title="Additional Purchaser Outside The Corporation"
                                    attribute="people"
                                    addMore="Add Another Purchaser"
                                    max={data.config.individualMax}
                                />,
                            ]}
                        />
                    ),
                    validate: ['business', 'officers', 'people'],
                    yup,
                };
            default:
                break;
        }
    }

    function getBrokerForm(auction, brokers, account) {
        // Exclusive broker listing | No commission
        if (auction.brokerId || !auction.commissionId) {
            return [];
        }

        // Broker selection
        return [
            {
                body: (
                    <>
                        <MultipleChoice
                            yup={yup}
                            attribute="brokerId"
                            body="<p>Are you working with a broker to facilitate this purchase?</p>"
                            options={[
                                {
                                    value: '',
                                    label: 'No Broker',
                                },
                                ...brokers.map((broker) => ({
                                    value: broker.id,
                                    label: `${broker.firstName} ${broker.lastName}`,
                                })),
                            ]}
                        />
                        <Typography marginTop={2}>
                            Don't see your broker? You'll need to register your
                            association with them on your{' '}
                            <a
                                href={`/user/${account.user.username}/account`}
                                target="_blank"
                            >
                                profile page
                            </a>
                            . Once your broker approves your request, you'll be
                            able to find them here.
                        </Typography>
                    </>
                ),
            },
        ];
    }

    function getUpgradeOptions(upgrades = []) {
        let key = -1;

        return upgrades.map((upgradeGroup, i) => {
            const max = upgradeGroup[0].maxQuantity;
            const choices = yup.getValues()?.upgrades;

            return {
                body: (
                    <>
                        <Typography fontWeight="bold" gutterBottom>
                            {upgradeGroup[0].title}
                        </Typography>
                        <Typography component="div">
                            <HTMLParse value={upgradeGroup[0].description} />
                        </Typography>
                        {upgradeGroup[0].maxQuantity > 0 && (
                            <Typography
                                color="status.danger"
                                marginTop={2}
                            >{`Limit ${upgradeGroup[0].maxQuantity} item(s)`}</Typography>
                        )}
                        {upgradeGroup.map((upgrade, j) => {
                            key += 1;
                            const value = yup.getValues().upgrades[key];

                            let usage = 0;
                            if (max > 0) {
                                // Calculate usage when there is a limitation on the max #
                                for (
                                    let ii = 0;
                                    ii < upgradeGroup.length;
                                    ii++
                                ) {
                                    const upgrade = upgradeGroup[ii];
                                    for (
                                        let jj = 0;
                                        jj < upgrade.upgradeOptions.length;
                                        jj++
                                    ) {
                                        const option =
                                            upgrade.upgradeOptions[jj];

                                        if (
                                            choices.includes(
                                                option.id.toString()
                                            ) &&
                                            value !== option.id.toString()
                                        ) {
                                            usage += option.quantity;
                                        }
                                    }
                                }
                            }

                            return (
                                <MultipleChoice
                                    key={key}
                                    attribute={`upgrades.${key}`}
                                    yup={yup}
                                    title={
                                        upgrade.maxQuantity > 0
                                            ? upgrade.upgradeOptions[0]
                                                  ?.upgradeSubtype?.title
                                            : null
                                    }
                                    options={[
                                        ...upgrade.upgradeOptions.map(
                                            (upgradeOption) => {
                                                const price =
                                                    upgradeOption.price;
                                                const iPrice =
                                                    upgradeOption.incentivePrice;
                                                let label = upgradeOption.title;

                                                const discounted =
                                                    iPrice !== undefined;

                                                if (price > 0) {
                                                    label = `${
                                                        upgradeOption.title
                                                    } (${getPriceString(
                                                        discounted
                                                            ? iPrice
                                                            : price
                                                    )}) ${
                                                        discounted
                                                            ? `(Retail: ${getPriceString(
                                                                  price
                                                              )})`
                                                            : ''
                                                    }`;
                                                } else if (
                                                    upgradeOption.quantity > 0
                                                ) {
                                                    label +=
                                                        ' (Included with purchase)';
                                                }

                                                return {
                                                    value: upgradeOption.id,
                                                    label: label,
                                                    disabled:
                                                        (max > 0 &&
                                                            max - usage <
                                                                upgradeOption.quantity) ||
                                                        !upgradeOption.available,
                                                };
                                            }
                                        ),
                                    ]}
                                />
                            );
                        })}
                    </>
                ),
                validate: ['upgrades'],
            };
        });
    }

    return (
        <Box backgroundColor="tint">
            <PaddedBox
                left={{ xs: 0, md: '8vw', lg: '12vw' }}
                right={{ xs: 0, md: '8vw', lg: '12vw' }}
                bottom={{ xs: 4, md: 0 }}
            >
                <StepByStepForm
                    yup={yup}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    pages={[
                        {
                            body: (
                                <Instructions1
                                    auction={data.auction}
                                    user={data.user}
                                />
                            ),
                            // button: 'Continue',
                        },
                        {
                            body: (
                                <Instructions2
                                    auction={data.auction}
                                    incentives={data.incentives}
                                    depositSchedule={data.depositSchedule}
                                />
                            ),
                        },
                        ...getBrokerForm(data.auction, brokers, account),
                        {
                            body: (
                                <MultipleChoice
                                    yup={yup}
                                    attribute="purchaser"
                                    body="<p>Is the principal purchaser of this property an individual, or a corporation.</p>"
                                    options={[
                                        ...(data.config.disableIndividual
                                            ? []
                                            : [
                                                  {
                                                      value: purchaserTypes
                                                          .INDIVIDUAL.id,
                                                      label: purchaserTypes
                                                          .INDIVIDUAL.title,
                                                  },
                                              ]),
                                        ...(data.config.disableCorporate
                                            ? []
                                            : [
                                                  {
                                                      value: purchaserTypes
                                                          .CORPORATE.id,
                                                      label: purchaserTypes
                                                          .CORPORATE.title,
                                                  },
                                              ]),
                                        ...(data.config
                                            .disableCorporateAndIndividual
                                            ? []
                                            : [
                                                  {
                                                      value: purchaserTypes
                                                          .CORPORATE_INDIVIDUAL
                                                          .id,
                                                      label: purchaserTypes
                                                          .CORPORATE_INDIVIDUAL
                                                          .title,
                                                  },
                                              ]),
                                    ]}
                                />
                            ),
                            validate: ['purchaser'],
                        },
                        {
                            body: (
                                <MultipleChoice
                                    yup={yup}
                                    attribute="occupancyDeclaration"
                                    body="<p>What is the intended use of this property?</p>"
                                    options={[
                                        {
                                            value: occupancyDeclaration.PERSONAL
                                                .id,
                                            label: occupancyDeclaration.PERSONAL
                                                .title,
                                            disabled:
                                                +purchaser !==
                                                purchaserTypes.INDIVIDUAL.id,
                                        },
                                        {
                                            value: occupancyDeclaration.FAMILY
                                                .id,
                                            label: occupancyDeclaration.FAMILY
                                                .title,
                                            disabled:
                                                +purchaser !==
                                                purchaserTypes.INDIVIDUAL.id,
                                        },
                                        {
                                            value: occupancyDeclaration
                                                .INVESTMENT.id,
                                            label: occupancyDeclaration
                                                .INVESTMENT.title,
                                        },
                                    ]}
                                />
                            ),
                        },
                        getPurchaserForm(),
                        {
                            body: (
                                <MultipleChoice
                                    yup={yup}
                                    attribute="manualPaymentOptionId"
                                    body="<p>How would you like to pay your purchase deposits?</p>"
                                    options={data.paymentMethods?.map(
                                        (method) => ({
                                            value: method.id || 0,
                                            label: method.title,
                                        })
                                    )}
                                />
                            ),
                        },
                        {
                            body: <Instructions3 />,
                        },
                        ...getUpgradeOptions(data.upgrades),
                        {
                            body: (
                                <Summary
                                    yup={yup}
                                    auction={data.auction}
                                    upgrades={data.upgrades}
                                />
                            ),
                            button: 'Confirm Purchase Details',
                            confirmation: {
                                title: 'Confirm Purchase Details?',
                                body: 'Once you confirm your purchase details you, and any additional purchasers, will be able to complete the verification process and electronically sign your purchase agreement to complete your transaction.',
                            },
                        },
                    ]}
                    errors={errors}
                    setErrors={setErrors}
                />
            </PaddedBox>
            <Footer />
        </Box>
    );
}

function PurchaserContainer({ title, items }) {
    return (
        <Box>
            <Typography
                textTransform="uppercase"
                fontSize={18}
                marginBottom={2}
                fontWeight="medium"
            >
                {title}:
            </Typography>

            {items.map((item, i) => (
                <Box key={i}>{item}</Box>
            ))}
        </Box>
    );
}

function PersonForm({ yup, title, attribute, addMore, officer, max }) {
    function canAddMore() {
        const length = yup.getValues()[attribute].length;
        return length < max;
    }

    function handleAdd() {
        if (canAddMore()) {
            yup.setValue(
                `${attribute}.${yup.getValues()[attribute].length}`,
                {}
            );
        }
    }

    function handleRemove(i) {
        // Get current value (array)
        let items = yup.getValues()[attribute];

        // Remove the requested item
        items.splice(i, 1);

        // Update the entire object with the new list
        yup.setValue(attribute, items);
    }

    const value = yup.watch(attribute);

    return (
        <Box marginBottom={4}>
            {value.map((person, i) => {
                const inputs = getPersonInputs(officer);

                return (
                    <Box key={i}>
                        <Box display="flex" marginBottom={2}>
                            <Typography
                                fontWeight="bold"
                                textTransform="uppercase"
                                marginTop="auto"
                                marginRight="auto"
                                marginBottom="auto"
                            >
                                {title} ({`#${i + 1}`})
                            </Typography>
                            {i > 0 && (
                                <IconButton onClick={() => handleRemove(i)}>
                                    <Close />
                                </IconButton>
                            )}
                        </Box>
                        <InputForm
                            yup={yup}
                            parentAttribute={attribute}
                            index={i}
                            inputs={inputs}
                        />
                        {value.length > i + 1 && <hr />}
                    </Box>
                );
            })}
            <Button
                variant="white"
                size="small"
                type="border"
                sx={{
                    paddingLeft: 5,
                    paddingRight: 5,
                }}
                fullWidth
                onClick={handleAdd}
                disabled={!canAddMore()}
            >
                {addMore}
            </Button>
        </Box>
    );
}
function BusinessForm({ yup, attribute }) {
    const [businesses, setBusinesses] = useState([]);
    const [showAddBusiness, setShowAddBusiness] = useState(false);

    useEffect(() => {
        getBusinesses();
    }, []);

    function getBusinesses() {
        // Get the user's businesses
        axios
            .get(`/business/getAll`)
            .then((res) => {
                setBusinesses(res.data);
            })
            .catch(function () {});
    }

    return (
        <Box marginBottom={4}>
            <Typography
                fontWeight="bold"
                gutterBottom
                textTransform="uppercase"
            >
                Business Information
            </Typography>
            <InputForm
                yup={yup}
                parentAttribute={attribute}
                inputs={getBusinessInputs(businesses)}
                index={0}
            />
            <Button
                variant="dark"
                size="tiny"
                fullWidth
                onClick={() => setShowAddBusiness(true)}
            >
                Add Business
            </Button>
            <BusinessModal
                show={showAddBusiness}
                onClose={() => setShowAddBusiness(false)}
                onSuccess={getBusinesses}
            />
        </Box>
    );
}

function Line({ prefix, value, marginBottom = '1rem', boldPrefix = true }) {
    if (prefix) {
        return (
            <Box marginBottom={marginBottom} display="flex">
                <Typography
                    fontWeight={boldPrefix ? 'bold' : undefined}
                    marginRight={1}
                >
                    {prefix}
                </Typography>
                <Typography>{value}</Typography>
            </Box>
        );
    }

    return <Typography marginBottom={marginBottom}>{value}</Typography>;
}

function Instructions1({ auction, user }) {
    const { property } = auction;
    const { address } = property;

    return (
        <Box>
            <Line value={`Hi ${user.contactName},`} />
            <Line
                value={`Congratulations on reserving ${property.unit} at ${address.title}!`}
            />
            <Line
                value={`The next step will help ensure you secure the unit and can add all of your desired options. You'll also have an opportunity to add additional buyers or a business to the transaction, should you wish to purchase the unit through a corporation.`}
            />
        </Box>
    );
}

function Instructions2({ auction, incentives = [], depositSchedule }) {
    const { property } = auction;
    const { address } = property;

    return (
        <Box>
            <Line
                value={`Once complete. you'll be able to complete your verification and sign your purchase agreement via DocuSign.`}
            />
            <Line value={`Here's a summary so far:`} />

            <Line prefix={`Your unit:`} boldPrefix={true} />

            {!!property.unit && <Line marginBottom={0} value={property.unit} />}
            <Line marginBottom={0} value={address.title} />
            <Line marginBottom={0} value={address.streetAddress} />
            <Line value={`${address.city}, ${address.province}`} />

            <Line
                prefix={pluralize('Bedroom', property.model.bedroomCount, ':')}
                value={property.model.bedroomCount}
                marginBottom={0}
            />

            {property.model.denCount > 0 && (
                <Line
                    prefix={pluralize('Den', property.model.denCount, ':')}
                    value={property.model.denCount}
                    marginBottom={0}
                />
            )}
            <Line
                prefix={pluralize(
                    'Bathroom',
                    property.model.bathroomCount,
                    ':'
                )}
                value={property.model.bathroomCount}
                marginBottom={0}
            />
            <Line
                prefix="Area:"
                value={`${property.model.interiorSquareFeet} SQFT`}
            />

            <Line
                prefix="Purchase Price:"
                value={getPriceString(auction.bids[0].amount)}
            />

            {incentives.length > 0 && (
                <>
                    <Line prefix="Incentives:" boldPrefix={true} />
                    {incentives.map((incentive) => (
                        <Line
                            key={incentive.id}
                            prefix={`${incentive.title}:`}
                            value={incentive.description}
                        />
                    ))}
                </>
            )}

            <Line prefix="Deposit Schedule:" boldPrefix={true} />
            <TableContainer sx={{ marginBottom: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {depositSchedule.deposits.map((deposit) => (
                            <TableRow key={deposit.id}>
                                <TableCell>{deposit.number}</TableCell>
                                <TableCell>
                                    {deposit.fixedAmount
                                        ? getPriceString(deposit.fixedAmount)
                                        : deposit.subtraction > 0
                                        ? `${
                                              deposit.percent
                                          }% Less ${getPriceString(
                                              deposit.subtraction
                                          )}`
                                        : `${deposit.percent}%`}
                                </TableCell>
                                <TableCell>{deposit.dueString}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function Instructions3() {
    return (
        <Box>
            <Line value={`Great job. Now comes the fun part.`} />
            <Line
                value={`In the next step, you'll get to choose which additional options you would like to add to your unit.`}
            />
        </Box>
    );
}

function Summary({ auction, yup, upgrades }) {
    const { property } = auction;
    const { model } = property;

    const selectedUpgrades = yup.watch('upgrades');
    const people = yup.watch('people');
    const officers = yup.watch('officers');
    const manualPaymentOptionId = yup.watch('manualPaymentOptionId');

    const [data, setData] = useState();

    useEffect(() => {
        axios
            .post(`/postPurchase/getSummary`, {
                slug: auction.slug,
                upgradeOptions: yup.getValues()?.upgrades,
                manualPaymentOptionId,
            })
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const upgradeSelection = upgrades.map((upgradeGroup, i) =>
        upgradeGroup.map((upgrade, j) => {
            // Get the upgrade option that was selected for this upgrade
            let upgradeOption = {};
            for (let k = 0; k < upgrade.upgradeOptions.length; k++) {
                if (
                    selectedUpgrades.includes(
                        upgrade.upgradeOptions[k].id.toString()
                    )
                ) {
                    upgradeOption = upgrade.upgradeOptions[k];
                    break;
                }
            }

            // Item not available
            if (!upgradeOption.title) {
                return {
                    price: 0,
                    quantity: 0,
                };
            }

            return {
                title: `${upgrade.title} (${upgradeOption?.title})`,
                price: upgradeOption?.price,
                quantity: upgradeOption?.quantity,
            };
        })
    );

    if (!data) {
        return <Loading height={500} />;
    }

    return (
        <Box>
            <Line value={`Confirm and submit your purchase details below.`} />

            <hr />

            <Typography
                fontWeight="bold"
                fontSize={18}
                marginBottom={1.5}
                textAlign="center"
            >
                {auction.title}
            </Typography>

            <Line prefix={model.title} marginBottom={0} />
            <Line
                prefix={pluralize('Bedroom', model.bedroomCount, ':')}
                value={model.bedroomCount}
                marginBottom={0}
            />
            {model.denCount > 0 && (
                <Line
                    prefix={pluralize('Den', model.denCount, ':')}
                    value={property.model.denCount}
                    marginBottom={0}
                />
            )}
            <Line
                prefix={pluralize('Bathroom', model.bathroomCount, ':')}
                value={model.bathroomCount}
                marginBottom={0}
            />
            <Line prefix="Area:" value={`${model.interiorSquareFeet} SQFT`} />

            <Line
                prefix="Base Price:"
                value={getPriceString(data.basePrice)}
                marginBottom={0}
            />

            {upgradeSelection.map((upgradeOptionGroup, i) =>
                upgradeOptionGroup.map((upgradeOption, j) => {
                    if (
                        upgradeOption.price === 0 &&
                        upgradeOption.quantity === 0
                    ) {
                        return null;
                    }

                    return (
                        <Line
                            key={`${i}-${j}`}
                            prefix={`${upgradeOption.title}:`}
                            value={
                                upgradeOption.price === 0
                                    ? 'Included with purchase'
                                    : getPriceString(upgradeOption.price)
                            }
                            marginBottom={0}
                        />
                    );
                })
            )}
            <Line
                prefix="Total Add-ons:"
                value={getPriceString(data.upgradePrice)}
                marginBottom={0}
            />
            <Line prefix="Total:" value={getPriceString(data.initialPrice)} />

            <Typography fontWeight="bold">Less:</Typography>
            <Line
                prefix="Base Price Discounts:"
                value={getPriceString(-data.basePriceDiscount)}
                marginBottom={0}
            />
            <Line
                prefix="Add-on Discounts:"
                value={getPriceString(-data.upgradeDiscount)}
                marginBottom={0}
            />
            <Line
                prefix="Total Discounts:"
                value={getPriceString(-data.totalDiscount)}
            />
            <Line
                prefix="Purchase Price:"
                value={getPriceString(data.finalPrice)}
            />

            {data.manualPaymentOption && (
                <Line
                    prefix="Payment Type:"
                    value={data.manualPaymentOption.title}
                />
            )}

            <Typography fontWeight="bold">Deposit Schedule</Typography>
            <TableContainer sx={{ marginBottom: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.deposits.map((deposit) => (
                            <TableRow key={deposit.id}>
                                <TableCell>{deposit.number}</TableCell>
                                <TableCell>
                                    {getPriceString(deposit.amount)}
                                </TableCell>
                                <TableCell>{deposit.dueString}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography fontWeight="bold">Purchasers</Typography>

            <ul>
                {officers.map((officer, i) => (
                    <Signer key={i} signature={officer} isOfficer />
                ))}
                {people.map((people, i) => (
                    <Signer key={i} signature={people} />
                ))}
            </ul>

            <hr />

            <Line
                prefix="Confirm & Submit Purchase Details"
                boldPrefix={true}
            />
            <Line value="Need to make changes to your add-ons,  purchasers or other details? Simply return the previous steps before you submit." />

            <Line
                value={
                    <HTMLParse
                        value={`Questions? Chat with us via our live chat or contact <a href="mailto:support@upside.re">support@upside.re</a> and we'd be happy to help.`}
                    />
                }
            />
        </Box>
    );
}

function Signer({ signature, isOfficer }) {
    return (
        <Box component="li">
            <Typography>{`${signature.firstName} ${signature.lastName}${
                isOfficer ? ' (Officer)' : ''
            } - ${signature.email}`}</Typography>
        </Box>
    );
}

const purchaserTypes = {
    INDIVIDUAL: { id: 1, title: 'Individual(s)' },
    CORPORATE: { id: 2, title: 'Corporate' },
    CORPORATE_INDIVIDUAL: { id: 3, title: 'Corporate and Individual(s)' },
};

function getPersonInputs(officer) {
    return officer
        ? [
              [
                  { id: 'position' },
                  {
                      id: 'title',
                      children: getTitles(),
                  },
              ],
              [{ id: 'firstName' }],
              [{ id: 'lastName' }],
              [{ id: 'email' }],
          ]
        : [
              [
                  {
                      id: 'title',
                      children: getTitles(),
                  },
              ],
              [{ id: 'firstName' }],
              [{ id: 'lastName' }],
              [{ id: 'email' }],
          ];
}

function getBusinessInputs(businesses = []) {
    return [
        [
            {
                id: 'businessId',
                children: businesses.map((business) => ({
                    id: business.id,
                    title: `${business.title} (${business.businessNumber})`,
                })),
                label: 'Business',
            },
        ],
    ];
}

export default PostPurchasePage;
